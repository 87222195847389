import { MemoizedSelector, createSelector, createFeatureSelector } from '@ngrx/store';
import { State } from './mobile.state';

export const selectMobile: MemoizedSelector<object, State>
    = createFeatureSelector<State>('mobile');

export const selectIsMobile: MemoizedSelector<object, boolean> = createSelector(
    selectMobile,
    ( state: State ) => state.isMobile
);

export const selectIsFromApp: MemoizedSelector<object, boolean> = createSelector(
    selectMobile,
    ( state: State ) => state.fromApp
);