import { Directive, Injector, ElementRef, Input, Optional, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable, fromEvent, merge, of, Subscription } from 'rxjs';
import { mapTo, flatMap, delay, takeUntil, tap, map, takeWhile } from 'rxjs/operators';
import { TooltipComponent, TOOLTIP_TEXT } from '../components/tooltip/tooltip.component';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { RootStoreState } from 'src/app/store';
import { Store, select } from '@ngrx/store';
import { selectIsMobile } from '@app/store/mobile/mobile.selectors';

@Directive({
  selector: '[mypHoverTooltip]'
})
export class HoverTooltipDirective implements OnInit, OnDestroy {

  @Input('mypHoverTooltip')
  text: string;

  @Input()
  hoverDelay: number;

  // @Input()
  // tooltipAnchor: ElementRef<HTMLSpanElement>;

  ref: OverlayRef;

  in$: Observable<void>;
  out$: Observable<void>;

  inOut$: Subscription;
  isMobile$ = this.store.select( selectIsMobile );

  alive = true;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private element: ElementRef,
    public store: Store<RootStoreState.State>
  ) {

  }

  ngOnInit() {

    // const labelWidth = this.tooltipAnchor.nativeElement.getClientRects()[0].width;
    this.ref = this._createOverlay();
  
    this.isMobile$.pipe(
      takeWhile(() => this.alive),
      map(isMobile => {
        this.in$ = isMobile ? fromEvent(this.element.nativeElement, 'touchstart') : fromEvent(this.element.nativeElement, 'mouseover');
        this.out$ = isMobile ? fromEvent(this.element.nativeElement, 'touchend') : fromEvent(this.element.nativeElement, 'mouseout');

        const component = new ComponentPortal(
          TooltipComponent,
          null,
          this._createDescriptionInjector(this.injector, this.text)
        );
  
        this.inOut$ = merge(
          this.in$.pipe(
            flatMap( x => {
              return of(true).pipe(
                delay(this.hoverDelay || 0),
                takeUntil(this.out$)
              );
            })
          ),
          this.out$.pipe( mapTo(false) )
        ).pipe(
          tap( isHovering => {
            if ( isHovering ) {
              this.ref.attach(
                component
              );
            } else {
              this.ref.detach();
            }
          })
        ).subscribe();
      }
    )).subscribe();
  }

  ngOnDestroy() {
    // Hello!
    if ( this.ref.hasAttached() ) {
      this.ref.detach();
    }

    this.inOut$.unsubscribe();
    this.alive = false;
  }

  _createOverlay() {
    return this.overlay.create({
      positionStrategy: this.overlay.position().connectedTo(
        this.element.nativeElement,
        {
          originX: 'center',
          originY: 'top'
        },
        {
          overlayX: 'center',
          overlayY: 'bottom'
        }
      )
    });
  }

  _createDescriptionInjector(injector: Injector , text: string) {
    const tokens = new WeakMap();

    tokens.set( TOOLTIP_TEXT , text);


    return new PortalInjector(
      injector, tokens
    );
  }

}
