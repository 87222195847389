import { Component, OnInit, EventEmitter, Output, InjectionToken, Inject, Optional, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, tap, first } from 'rxjs/operators';
import { of } from 'rxjs';

export const REGISTRATION_EMAIL = new InjectionToken<string>('registration_email');

@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss']
})
export class RegistrationModalComponent implements OnInit {

  showResend = false;
  showSupport = false;

  @HostBinding('style.width.px')
  modalWidth = 500;

  @HostBinding('style.paddingLeft.px')
  @HostBinding('style.paddingRight.px')
  padding = 88;

  @Output()
  close: EventEmitter<void> = new EventEmitter();

  requestEmail: FormControl;
  error: any = false;
  emailSent = false;

  constructor(
    @Inject(REGISTRATION_EMAIL)
    @Optional()
    public email: string,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.requestEmail = new FormControl('', [Validators.pattern(
      // tslint:disable-next-line:max-line-length
      /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
    ), Validators.required]);
  }

  goToLogin() {
    this.close.emit();
    this.close.complete();
    this.router.navigateByUrl('./login');
  }

  requestResend() {
    this.showResend = true;
    this.modalWidth = 550;
    this.padding = 48;
  }

  showSupportModal() {
    this.showResend = false;
    this.showSupport = true;
  }

  resendActivationEmail() {

    this.emailSent = false;
    this.error = '';

    if ( this.requestEmail.hasError('required') ) {
      this.error = `Non è stata inserita nessuna email`;
    } else if ( this.requestEmail.hasError('email') ) {
      this.error = `L'indirizzo inserito non è un indirizzo email valido`;
    } else {

      this.error = null;
      //console.log('SENDING!');

      // MAKE HTTP CALL!
      this.http.post<HttpResponse<any>>(
        `${environment.serverUrl}/customers/signup/email`,
        {
          email: this.requestEmail.value
        },
        {
          observe: 'response'
        }
      ).subscribe(
        response => {
          this.emailSent = true;
          this.error = false;
        },
        ( error: HttpErrorResponse ) => {

          const statusCode = error.status;

          switch (statusCode) {
            case 404:
              this.error = `L'email inserita non è presente nei nostri database`;
              break;
            case 409:
              this.error = `L'account associato è già attivo`;
              break;
            case 403:
              this.error = `Il token di registrazione è scaduto. Pervafore ripetere la registrazione`;
              break;
            default:
              this.error = `Impossibile contattare il server, riprovare più tarti`;
              break;
          }

        },
        () => {
          //console.log('DONE');
        }
      );

    }


  }

}
