import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { AuthStoreModule } from "./auth";
import { ActivationStoreModule } from "./activation";
import { MobileStoreModule } from "./mobile";
// import { RegistrationStoreModule } from './registration';
// import { CustomerStoreModule } from './customer';
// import { AdvisorStoreModule } from './advisor';

@NgModule({
  imports: [
    CommonModule,

    // STORE MODULES
    AuthStoreModule,
    ActivationStoreModule,
    MobileStoreModule,
    // RegistrationStoreModule,
    // CustomerStoreModule,
    // AdvisorStoreModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ],
  declarations: [],
})
export class RootStore {}
