import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {RootStoreState} from 'src/app/store';
import {selectUserFeatures} from 'src/app/store/auth/auth.selectors';
import {map, tap, withLatestFrom} from 'rxjs/operators';
import { FeatureChecked } from '@app/store/auth/auth.actions';

const hasPermission = (userPermissions: string[], required: string) => {
  return userPermissions && userPermissions.indexOf(required) > -1;
};

@Injectable()
export class ChildFeatureGuard implements CanActivateChild {

  // count = 0;

  constructor(
    private router: Router,
    private store: Store<RootStoreState.State>
  ) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) {

    return this.store.select(selectUserFeatures).pipe(
      map(userFeatures => {

        let outcome = false;

        if (Array.isArray(route.data['feature'])) {
          (route.data['feature'] as Array<string>).forEach(feature => {
            const hasSaidFeature = hasPermission(userFeatures, feature);
            // console.log(`Said feature: ${feature} presence: ${hasSaidFeature}`);
            outcome = outcome || hasSaidFeature;
          });
        } else {
          outcome = hasPermission(userFeatures, route.data['feature']);
        }

        return outcome;

      }),
      tap(
        canEnter => {
          // this.count++;
          // //console.log(`CanActivateChild called ${this.count} times`);
          if (!canEnter) {
            //console.log(`User got NO ${route.data['feature']}. Permission denied. `);
            // console.warn('GET OUT OF HERE');
            this.redirect();
          }
        }
      )
    );

  }

  redirect(): void {
    this.router.navigateByUrl('/login');
  }

}
