import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { RootStoreState } from '@app/store';
import { Store } from '@ngrx/store';
import { SwitchToAdvisorCode, FetchVaCodes } from '@app/store/auth/auth.actions';
import { selectFetchedVACodeList, selectLoadingVaCodeList } from '@app/store/auth/auth.selectors';
import { VirtualOperator } from '@app/store/auth/auth.effects';
import { distinctUntilChanged, debounceTime, tap, mergeMap, map, scan, share } from 'rxjs/operators';

@Component({
  selector: 'app-virtual-advisor-picker',
  templateUrl: './virtual-advisor-picker.component.html',
  styleUrls: ['./virtual-advisor-picker.component.scss']
})
export class VirtualAdvisorPickerComponent implements OnInit {

  _count: BehaviorSubject<number> = new BehaviorSubject(0);
  count$: Observable<any>;

  loading$: Observable<boolean>;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  operators$: Observable<Array<VirtualOperator>>;


  keyword$: Subject<string>;

  shouldShowVaResults$: Observable<boolean>;

  constructor(
    private store: Store<RootStoreState.State>
  ) { }

  ngOnInit() {

    this.keyword$ = new Subject();

    this.loading$ = this.store.select( selectLoadingVaCodeList );
    this.operators$ = this.store.select( selectFetchedVACodeList );

    this.count$ = this._count.pipe(
      scan(
        ( acc, v ) => {
          return acc + 1;
        }, -1
      ),
      share()
    );

    this.shouldShowVaResults$ = this.count$.pipe(
      map( x => x > 0)
    );

    // stream
    this.keyword$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      // filter( x => x.length > 2)
      tap( ( x ) => {
        this._count.next(0);
        this.onSearch( x );
      })
    ).subscribe();

  }

  pick( code: string ) {
    //console.log(`Switching to ${code}`);

    this.store.dispatch( new SwitchToAdvisorCode({ code }) );
    this.select.emit();
  }

  onSearch( query: string) {
    this.store.dispatch( new FetchVaCodes({query}));
  }

  operatorNameInList( va: VirtualOperator ) {

    if ( va.name.length > 0 ) {
      return `${va.name.charAt(0)}. ${va.surname} (${va.code})`;
    }
    return `${va.surname} (${va.code})`;
  }

}
