import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, Injector, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { OptionListComponent, OPTION_LIST } from '../../option-list/option-list.component';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { of } from 'rxjs';
import { selectIsMobile } from '@app/store/mobile/mobile.selectors';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@app/store';

@Component({
  selector: 'myp-table-paging',
  templateUrl: './table-paging.component.html',
  styleUrls: ['./table-paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablePagingComponent implements OnInit {

  @Input()
  label = 'documenti';

  @Input()
  entriesCount: number;

  @Input()
  totalPages: number;

  @Input()
  currentPage: number;

  @Input()
  pageSize: number;

  @Output()
  previousPage: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  nextPage: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  // properties
  overlayRef: OverlayRef;

  pageCountComponentRef: ComponentRef<OptionListComponent>;
  optionListPortal: ComponentPortal<OptionListComponent>;

  isMobile$ = this.store.select( selectIsMobile );

  constructor(
    private overlay: Overlay,
    private _injector: Injector,
    private store: Store<RootStoreState.State>
  ) {}

  ngOnInit() {
    this.optionListPortal = new ComponentPortal (
      OptionListComponent,
      null,
      this.createPageCountInjector( this._injector, [5, 10, 20, 50] )
    );
  }

  /* Page Count Selector in overlay */
  openPageCountOptions( element: ElementRef) {

    if ( this.entriesCount === 0 ) {
      return;
    }

    if ( this.overlayRef === undefined ) {
      this.overlayRef = this.overlay.create(
        {
          hasBackdrop: true,
          backdropClass: 'background-transparent',
          // positionStrategy: this.overlay.position().flexibleConnectedTo( element )
          positionStrategy: this.overlay
            .position()
            .connectedTo( element,
              {
                originX: 'end',
                originY: 'top'
              },
              {
                overlayX: 'end',
                overlayY: 'bottom'
              }
            )
        }
      );
      this.overlayRef.backdropClick().subscribe(
        click => {
          this.overlayRef.detach();
        }
      );
    }

    if ( this.overlayRef.hasAttached() ) {
      // a bit hacky but works!
      // it also closes the "modal" ( subscription below detaches the component)
      this.pageCountComponentRef.instance.select.complete();
    }

    this.pageCountComponentRef = this.overlayRef.attach(
      this.optionListPortal
    );


    this.pageCountComponentRef.instance.select.subscribe(
      v => {
        this.pageSizeChange.emit(v);
      },
      () => console.error,
      () => {
        this.overlayRef.detach();
      }
    );

  }

  createPageCountInjector( injector: Injector, options: number[]) {

    const values = new WeakMap();
    values.set(OPTION_LIST, of(options) );
    return new PortalInjector(injector, values);

  }

}
