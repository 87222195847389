import { initialAuthState, State } from './auth.state';
import { Actions, ActionTypes } from './auth.actions';
import { stat } from 'fs';

export function authReducer( state: State = initialAuthState, action: Actions ) {

    switch (action.type) {

        case ActionTypes.FetchVaCodes:
            return { ...state, loadingVaCodes: true};
        case ActionTypes.FetchVaCodesSuccess:
            return {
                ...state,
                vaCodes: action.payload.codes,
                loadingVaCodes: false
            };
        case ActionTypes.SwitchAdvisorCode:
            return {
                ...state,
                pickedVaCode: action.payload.code
            };
        case ActionTypes.Login:
            return {
                ...state,
                loginError: false,
            };
        case ActionTypes.LoginSuccess:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.id
            };
        case ActionTypes.LoginFailed:
            return {
                ...state,
                token: null,
                userData: null,
                loginError: true
            };
        case ActionTypes.Logout:
            return {
                ...state,
                token: null,
                userData: null
            };
        case ActionTypes.ActivationCompleted:
            return {
                ...state,
                isActivating: false,
                activationCompleted: true,
                activationError: null
            };
        case ActionTypes.ActivationError:
            return {
                ...state,
                isActivating: false,
                activationCompleted: true,
                activationError: action.payload.error
            };
        case ActionTypes.FetchUserDataSuccess:
            return {
                ...state,
                profileData: action.payload
            };
        default:
            return state;
    }

}
