import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http';

import {Store} from '@ngrx/store';
import {RootStoreState} from 'src/app/store';
import {selectToken} from 'src/app/store/auth/auth.selectors';

import {Observable} from 'rxjs';
import {take, mergeMap, tap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<RootStoreState.State>
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectToken).pipe(
      take(1),
      mergeMap(token => {

          // const decoder = new JwtHelperService();
          // //console.log(" TOKEN CONTENT")
          // //console.log( decoder.decodeToken(token));

          if (!token) {
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            return next.handle(req);
          }

          return next.handle(
            req.clone({
              setHeaders: {
                'access_token': token
              }
            })
          ).pipe(tap(() => {
          }, (error) => {
            if (error && error.status && error.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('id');
            }
          }));
        }
      )
    );

  }


}
