export interface State {
    isLoading: boolean;
    success: boolean;
    error: boolean;
    message: string;
}

export const initialState: State = {
    isLoading: true,
    success: false,
    error: false,
    message: null
};
