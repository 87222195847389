import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, CanActivate } from '@angular/router';

@Injectable()
export class HasValidResetTokenGuard implements CanActivate {

    // count = 0;

    constructor(
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ) {

        const email = route.queryParamMap.get('email');
        const id = route.queryParamMap.get('id');

        if ( email !== null && id !== null ) {
            return true;
        } else {
            this.redirect();
            return false;
        }

    }

    redirect(): void {
        this.router.navigateByUrl('/login');
    }

}
