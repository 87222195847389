import { Component, OnInit, EventEmitter, Inject, Optional, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { OPTION_LIST } from '../option-list/option-list.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-option-input',
  templateUrl: './option-input.component.html',
  styleUrls: ['./option-input.component.scss']
})
export class OptionInputComponent implements OnInit, AfterViewInit {


  @ViewChild('input') input: ElementRef;

  select: EventEmitter<string> = new EventEmitter();

  value = 'default';
  placeholder = 'Inserisci testo...';

  constructor(
    @Inject(OPTION_LIST)
    @Optional()
    private options$: Observable<string>
  ) { }

  ngOnInit() {
    if ( this.options$ ) {
      this.options$.subscribe(
        v => {
          this.value = v;
        },
        err => console.error,
        () => {
          //console.log('THIS SHOULD BE SHOWN');
        }
      );
    }
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  apply() {
    this.select.emit( this.value );
    this.select.complete();
  }

}
