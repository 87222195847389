import { Directive, forwardRef, HostListener, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[appUppercase]',
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => UppercaseDirective),
  //     multi: true
  //   }
  // ]
})
export class UppercaseDirective { // extends DefaultValueAccessor {

  @HostBinding('value')
  value: any = '';

  @HostListener('input', ['$event'])
  onInput( $event ) {
    this.value = $event.target.value.toUpperCase();
  }

  // writeValue(value: string): void {
  //   if ( value != null ) {
  //     super.writeValue(value.toUpperCase());
  //   }
  // }



}
