import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/store';
import { isVirtualAdvisor, selectPickedVaCode } from 'src/app/store/auth/auth.selectors';

import { Observable } from 'rxjs';
import { mergeMap, take, map, combineLatest } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VirtualFaInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<RootStoreState.State>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.store.select( isVirtualAdvisor ).pipe(
            take(1),
            combineLatest( this.store.select( selectPickedVaCode ).pipe( take(1)) ),
            map(
                x => {
                    return {
                        isVa: x[0],
                        code: x[1]
                    };
                }
            ),
            // mergeMapTo( this.store.select(selectPickedVaCode).pipe(take(1)) ),
            mergeMap( values => {

                // //console.log("VALUES");
                // //console.log( values );

                const { isVa, code } = values;

                if ( !isVa ) {
                    return next.handle(req);
                }

                return next.handle(
                    req.clone({
                        setHeaders: {
                            'virtualFACode' : code || 'none'
                        }
                    })
                );

            })
        );

    }

}
