import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  template: `
  <div class="footer">
    <div class="left">
    <div class="description"><span class="trademark">&reg;</span>Allianz Bank Financial Advisors S.p.A.</div>
    <div class="description">CF e Registro imprese di Milano n. 09733300157</div>
    <div class="description">Società appartenente al Gruppo IVA Allianz con P. IVA n. 01333250320</div>
    </div>
    <div class="right">
      <div [style.marginRight.px]="48"><a href="https://www.allianzbank.it/privacy---cookie/privacy.html" target="_blank">Privacy</a></div>
      <div> <a href="https://www.allianzbank.it/chi-siamo/contatti/servizio-clienti.html" target="_blank">Servizio Clienti</a></div>
    </div>
  </div>
  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
