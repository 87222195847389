import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Input()
  breadcrumbs: any;

  constructor(
    private router: Router
  ) { }

  goto( url ) {
    if ( url ) {
      this.router.navigateByUrl( url );
    }
  }
}
