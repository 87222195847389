import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivatePageComponent } from './activate/activate.component';
import { MessageComponent } from './message/message.component';
import { HasErrorMessageGuard } from './configuration/guards/has-error-message';

const routes: Routes = [
  {path: 'login', loadChildren: 'src/app/login/login.module#LoginModule'},
  {
    path: 'activate',
    component: ActivatePageComponent
  },
  {
    path: 'message',
    component: MessageComponent,
    canActivate: [HasErrorMessageGuard]
  },
  {path: 'registration', loadChildren: 'src/app/registration/registration.module#RegistrationModule'},
  {path: 'reset', loadChildren: 'src/app/password-recovery/password-recovery.module#PasswordRecoveryModule'},

  {path: 'customer', loadChildren: 'src/app/customer/customer.module#CustomerModule'},
  {path: 'financial-advisor', loadChildren: 'src/app/financial-advisor/financial-advisor.module#FinancialAdvisorModule'},

  {path: 'monitoring', loadChildren: 'src/app/monitoring/monitoring.module#MonitoringModule'},

  // Default Route redirects to App Login
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  // scrollPositionRestoration: 'enabled'
  // Scroll position not working if body height == 100% ( add overflow to html )
  // https://github.com/angular/angular/issues/24547#issuecomment-404614298
  imports: [
    RouterModule.forRoot(
        routes,
        {
          useHash: true,
          scrollPositionRestoration: 'enabled',
          // enableTracing: true
        }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
