import {
  Component,
  OnInit,
  ElementRef,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  Input,
  OnDestroy,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Logout } from "src/app/store/auth/auth.actions";
import { RootStoreState } from "src/app/store";
import { selectUserData } from "src/app/store/auth/auth.selectors";
import {
  selectIsFromApp,
  selectIsMobile,
} from "@app/store/mobile/mobile.selectors";
import { map, takeWhile } from "rxjs/operators";
import { MatBottomSheet, MatDialog } from "@angular/material";

@Component({
  selector: "app-profile-popup",
  templateUrl: "./profile-popup.component.html",
  styleUrls: ["./profile-popup.component.scss"],
})
export class ProfilePopupComponent implements OnInit, OnDestroy {
  @Input()
  disableProfile = false;

  // @Input()
  // data: { email: string, name: string, date: string} = {
  //   email: 'email.com',
  //   name: 'name surname',
  //   date: '2019-01-30T09:23:56.167Z'
  // };

  // GOT FROM STORE... why not for now
  data: any;

  @ViewChild("popup")
  popupTemplate: TemplateRef<any>;

  @ViewChild("infoTemplate") infoTemplate: TemplateRef<any>;

  overlayRef: OverlayRef;
  overlayInfo: OverlayRef;
  isMobile$ = this.store.select(selectIsMobile);
  fromApp$ = this.store.select(selectIsFromApp);

  alive = true;

  constructor(
    private element: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private store: Store<RootStoreState.State>,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.data = this.store.select(selectUserData);

    this.data.subscribe((x) => {
      //console.log("USER DATA [Profile popup")
      //console.log(x);
    });

    this.isMobile$
      .pipe(
        takeWhile(() => this.alive),
        map((isMobile) => {
          this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().connectedTo(
              this.element,
              {
                originX: isMobile ? "start" : "end",
                originY: "bottom",
              },
              {
                overlayX: isMobile ? "start" : "end",
                overlayY: "top",
              }
            ),
            hasBackdrop: true,
            backdropClass: "transparent-bg",
            disposeOnNavigation: true,
          });

          this.overlayRef
            .backdropClick()
            .subscribe((x) => this.overlayRef.detach());
        })
      )
      .subscribe();
  }

  onClick() {
    // if ( this.disableProfile ) {
    //   return;
    // }

    if (this.overlayRef.hasAttached()) {
      this.close();
      return;
    }
    this.open();
  }

  openInfo() {
    this.dialog.open(this.infoTemplate, {
      panelClass: "info-container-cdk",
    });
  }

  closeInfo() {
    this.dialog.closeAll();
  }

  open() {
    this.overlayRef.attach(
      new TemplatePortal(this.popupTemplate, this.viewContainerRef)
    );
  }

  close() {
    this.overlayRef.detach();
  }

  goToProfilePage() {
    this.router.navigateByUrl("/customer/profilo");
    this.close();
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.close();
  }
}
