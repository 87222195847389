import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-mobile",
  templateUrl: "./header-mobile.component.html",
  styleUrls: ["./header-mobile.component.scss"],
})
export class HeaderMobileComponent implements OnInit {
  logoWidth = 130;
  logoHeight = 80;

  constructor() {}
  ngOnInit(): void {}
}
