
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

    transform(value: any, args: any): any {

        if ( ! args ) {
            return value;
        }

        let pattern = args.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
        }).join('|');
        const regex = new RegExp(pattern, 'i');

        return args ? value.replace(regex, (match) => `<mark class="custom">${match}</mark>`) : value;

    }
}
