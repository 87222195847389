import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-navbar',
  template: `
    <div class="navbar">
      <div class="left">
        <img class="logo" src="assets/images/logo_allianz.svg" alt="">
        <div>Mypost-Investiment</div>
      </div>
      <div>component action</div>

    </div>`,
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
