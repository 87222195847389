import { Component, OnInit, InjectionToken, Inject, Optional, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
export const OPTION_LIST = new InjectionToken<any>('OPTION_LIST');

@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss']
})
export class OptionListComponent implements OnInit {

  // @Output()
  select: EventEmitter<string> = new EventEmitter();

  constructor(
    @Inject(OPTION_LIST)
    @Optional()
    public options$: Observable<string[]>
  ) { }

  ngOnInit() {
    if ( this.options$ == null ) {
      this.options$ = of([
        'ok',
        'not bad',
        'too bad'
      ]);
    }
  }

  onSelect(option: string) {
    this.select.emit(option);
    this.select.complete();
  }

}
