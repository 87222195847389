import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, EmailValidator, Validators, ValidationErrors } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

type RecoveryType = 'password' | 'username';

@Component({
  selector: 'app-recovery-type-selector',
  templateUrl: './recovery-type-selector.component.html',
  styleUrls: ['./recovery-type-selector.component.scss']
})
export class RecoveryTypeSelectorComponent implements OnInit {

  // Used when password recovery cannot be done ( to show/hide modal contents ) [Happens on 401]
  canRequest = true;


  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  @Output()
  showLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  error: {message: string} | ValidationErrors = null;

  selectedType: RecoveryType = 'password';
  currentStep = 0;

  // PASSWORD RECOVERY
  passwordRecoveryField: FormControl;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit(): void {

    this.passwordRecoveryField = this.fb.control(
      // tslint:disable-next-line:max-line-length
      '', [ Validators.pattern(/^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/), Validators.required ]
    );

  }

  setType( type: RecoveryType ): void {
    this.selectedType = type;
  }

  proceedToRecovery() {
    this.currentStep = 1;
  }

  requestPasswordReset() {
    //console.log('[RecoveryTypeSelectorComponent] Requesting password');

    const errors = this.passwordRecoveryField.errors;

    //console.log(errors);

    if ( errors != null ) {

      if ( errors['required'] ) {
        this.error = { message: 'Inserisci un indirizzo email' };
        return;
      }

      if ( errors['email'] ) {
        //console.log(this.passwordRecoveryField.errors);
        this.error = { message: 'L\'indirizzo email fornito non è un indirizzo valido'};
        return;
      }


      this.error = { message: `Inserisci un'email valida`};
      return;
    }

    this.error = null;

    const endpoint = `${environment.serverUrl}/password/reset`;
    const payload = {
      email: this.passwordRecoveryField.value
    };

    this.showLoadingEvent.emit(true);
    this.http.post(
      endpoint, payload
    ).pipe(
      tap(
        result => {

          this.showLoadingEvent.emit(false);
          this.currentStep = this.currentStep + 1;
        },
        (err: HttpErrorResponse) => {

          this.showLoadingEvent.emit(false);

          switch (err.status) {
            case 404:
              this.error = { message: `L'email inserita non esiste!` };
              break;
            case 500:
              this.error = { message: `Al momento non è possibile raggiungere il server. La preghiamo di riprovare più tardi.` };
              break;
            case 401:
              this.canRequest = false;
              const description = err.error.error.description;
              this.error = { message: description };
              break;
              // //console.log( err.error.error.discription );
            case 400:
              // TODO Ask Matteo
              this.error = { message: `La richiesta di recupero password è fallita.`};
              break;
          }
        }
      )
    ).subscribe();
    

    // // NEXT STEP >> ASYNC VALIDATION
    // if ( this.passwordRecoveryField.value === 'valid@email.com' ) {
    //   this.currentStep = this.currentStep + 1;
    // } else {
    //   this.error = { message: 'L\'email inserita non esiste!' };
    // }


  }

  close() {
    //console.log('[RecoveryTypeSelectorComponent] Close');
    this.closeEvent.next(true);
  }

  @HostListener('document:keyup', ['$event'])
  onkeyup( key: KeyboardEvent ) {
    if ( key.keyCode === 27 ) {
      //console.log('[RecoveryTypeSelectorComponent] Esc key > Close');
      this.close();
    }
  }

}
