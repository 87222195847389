import { AfterViewInit, ChangeDetectorRef, Component, AfterContentChecked, OnInit, SimpleChanges } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngrx/store";
import { RootStoreState } from "./store";
import { SSOLogin, ActionTypes } from "./store/auth/auth.actions";
import { DetectMobileService } from "./shared/services/mobile/detect-mobile.service";
import { FromApp, IsMobile } from "./store/mobile/mobile.actions";
import { take } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
    <div *ngIf="isLoading" class="mobile-loader">
      <i class="mobile-spinner spin"></i>
    </div>
  `,
})
export class AppComponent implements OnInit, AfterViewInit {
  public isLoading = true;
  private detectMobileService = new DetectMobileService("(max-width: 992px)");

  constructor(
    private cookieService: CookieService,
    private store: Store<RootStoreState.State>,
    private loginActions$: Actions,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loginActions$.pipe(ofType(ActionTypes.FeatureChecked), take(1)).subscribe(() => {
      this.isLoading = false;
    });

    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("id") !== null
    ) {
      this.store.dispatch(
        new SSOLogin({
          token: localStorage.getItem("token"),
          id: localStorage.getItem("id"),
          redirect: false,
        })
      );

      // this.loginActions$.pipe(ofType(ActionTypes.LoginSuccess), take(1)).subscribe(() => {
      //   this.isLoading = false;
      // })
    }

    if (
      this.cookieService.check("mypost_access_token") &&
      this.cookieService.check("mypost_id_token")
    ) {
      localStorage.setItem(
        "token",
        this.cookieService.get("mypost_access_token")
      );
      localStorage.setItem("id", this.cookieService.get("mypost_id_token"));

      this.store.dispatch(
        new SSOLogin({
          token: this.cookieService.get("mypost_access_token"),
          id: this.cookieService.get("mypost_id_token"),
          redirect: true,
        })
      );

      // this.loginActions$.pipe(ofType(ActionTypes.LoginSuccess), take(1)).subscribe(() => {
      //   this.isLoading = false;
      // })
    }

    this.detectMobileService.match$.subscribe((isMobile) => {
      this.store.dispatch(
        new IsMobile({
          isMobile,
        })
      );
    });
    
    this.store.dispatch(
      new FromApp({
        fromApp: this.cookieService.check("mypost_mobile") && this.cookieService.get("mypost_mobile") == 'true', 
      })
    );
  }

  ngAfterViewChecked(changes: SimpleChanges): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    if (
      !(
        localStorage.getItem("token") !== null &&
        localStorage.getItem("id") !== null
      ) ||
      !(
        this.cookieService.check("mypost_access_token") &&
        this.cookieService.check("mypost_id_token")
      )
    ) {
      setTimeout(() => {
        this.isLoading = false;
      }, 500)
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 4000)
  }
}
