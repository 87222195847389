import { NaturalCustomer, LegalCustomer } from 'src/app/models';
import { VirtualOperator } from './auth.effects';

export interface State {
    userData: any;
    profileData: NaturalCustomer | LegalCustomer;
    token: string;

    loginError: boolean;

    isActivating: boolean;
    activationCompleted: boolean;
    activationError: string;

    vaCodes: Array<VirtualOperator>;

    isVirtualAdvisor: boolean;
    pickedVaCode: string;
    loadingVaCodes: boolean;
}

export const initialAuthState: State = {
    userData: null,
    profileData: null,
    token: null,
    loginError: null,
    isActivating: null,
    activationCompleted: null,
    activationError: null,

    vaCodes: [],
    isVirtualAdvisor: false,
    pickedVaCode: null,
    loadingVaCodes: false
};
