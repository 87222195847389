import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HasErrorMessageGuard implements CanActivate {

    // count = 0;

    constructor(
        private router: Router,
        private cookie: CookieService,
    ) {}

    canActivate(
    ) {
        // return true;
        const title = this.cookie.get('mypost_error_title');
        const description = this.cookie.get('mypost_error_description');

        if ( description ) {
            return true;
        } else {
            this.redirect();
            return false;
        }

    }

    redirect(): void {
        this.router.navigateByUrl('/login');
    }

}
