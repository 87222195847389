import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, of, forkJoin} from 'rxjs';
import {AdvisorPovs, CustomerPovs} from '../containers/dashboard/dashboard.component';
import {POV} from '../components/chart/chart.component';
import {catchError, map} from 'rxjs/operators';
import {Sort} from '@app/models/order';

export interface CountResult {
  count: number;
}

export interface MonitoringFilters {
  search?: any;
  skip?: number;
  limit?: number;
  sort?: Sort;
  toCsv?: boolean;
  filter?: {
    advisors?: Array<string>,
    customers?: Array<string>,
    documentType?: string,
    timespan?: {
      from: string,
      to: string,
      type: string
    }
  };
}

export const NO_FILTERS: MonitoringFilters = {filter: {}};

export interface GraphResult {
  // data: {
  values: number[];
  labels: string[];
  // };
}

export interface DetailsResult {
  data: any; // ???
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(
    public http: HttpClient
  ) {
  }

  fetchCustomerLoginCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/customers/logins/count`,
      filters
    );
  }

  fetchCustomerLoginCounterVA(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/va/customers/logins/count`,
      filters
    );
  }

  fetchCustomerLoginGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/customers/logins/graph`,
      filters
    );
  }

  fetchCustomerLoginGraphVA(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/va/customers/logins/graph`,
      filters
    );
  }

  fetchCustomerLoginDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/customers/logins`,
      filters
    );
  }

  fetchCustomerLoginDetailsVA(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/va/customers/logins`,
      filters
    );
  }

  fetchCustomerSignupCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/customers/signups/count`,
      filters
    );
  }

  fetchCustomerSignupCounterVA(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/va/customers/signups/count`,
      filters
    );
  }

  fetchCustomerSignupGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/customers/signups/graph`,
      filters
    );
  }

  fetchCustomerSignupGraphVA(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/va/customers/signups/graph`,
      filters
    );
  }


  fetchCustomerSignupDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/customers/signups`,
      filters
    );
  }

  fetchCustomerSignupDetailsVA(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/va/customers/signups`,
      filters
    );
  }

  fetchCustomerReadCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/documents/seenbycustomers/count`,
      filters
    );
  }

  fetchCustomerReadGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/documents/seenbycustomers/graph`,
      filters
    );
  }

  fetchCustomerReadDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/documents/seenbycustomers`,
      filters
    );
  }

  // FOR VA
  fetchCustomerReadCounterVA(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/va/documents/seenbycustomers/count`,
      filters
    );
  }

  fetchCustomerReadGraphVA(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/va/documents/seenbycustomers/graph`,
      filters
    );
  }

  fetchCustomerReadDetailsVA(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/va/documents/seenbycustomers`,
      filters
    );
  }


  fetchAdvisorReadCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {

    // const fx = {...filters};

    // fx.filter.advisors = ['2724U',
    // '4908M',
    // '50303',
    // '51347',
    // '2947U'];

    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbyadvisors/count`,
      filters
    );
  }

  fetchAdvisorReadGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {

    // const wSkipAndLimit = {...filters};
    // wSkipAndLimit.skip = 0;
    // wSkipAndLimit.limit = 10;

    // //console.log("READ GRAPH");
    // //console.log(filters);

    // delete filters.filter.advisors;

    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbyadvisors/graph`,
      // filters
      filters
    );
  }

  fetchAdvisorReadDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {

    // filters.skip = 0;
    // filters.limit = 20;
    // filters.filter.advisors = [
    //     '2724U',
    //     '4908M',
    //     '50303',
    //     '51347',
    //     '2947U'
    // ];

    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbyadvisors`,
      filters
    );
  }

  fetchAdvisorCustomerReadCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbycustomers/count`,
      filters
    );
  }

  fetchAdvisorCustomerReadGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbycustomers/graph`,
      filters
    );
  }

  fetchAdvisorCustomerReadDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/advisors/documents/seenbycustomers`,
      filters
    );
  }

  fetchAdvisorLoginCounter(filters: MonitoringFilters = NO_FILTERS): Observable<CountResult> {
    return this.http.post<CountResult>(
      `${environment.serverUrl}/monitoring/advisors/logins/count`,
      filters
    );
  }


  fetchAdvisorLoginGraph(filters: MonitoringFilters = NO_FILTERS): Observable<GraphResult> {
    return this.http.post<GraphResult>(
      `${environment.serverUrl}/monitoring/advisors/logins/graph`,
      filters
    );
  }

  fetchAdvisorLoginDetails(filters: MonitoringFilters = NO_FILTERS): Observable<DetailsResult> {
    return this.http.post<DetailsResult>(
      `${environment.serverUrl}/monitoring/advisors/logins`,
      filters
    );
  }

  fetchCountersFromPov(pov: Array<POV>, userData: any, filters: MonitoringFilters): Observable<any> {

    //console.log("COUNTERs: is va:" + JSON.stringify(userData));

    // const fx = {...filters};
    // fx.skip = 0;
    // fx.limit = 10;

    const calls: Array<Observable<CountResult>> = pov.map(
      (_pov, idx) => this.resolvePovCounter(_pov.id, userData.virtualFA, filters).pipe(
        catchError(() => {
          return of({count: -1});
        })
      )
    );

    return forkJoin(calls).pipe(
      map(results => {
        const obj = {};
        obj[pov[0].id] = results[0].count;
        obj[pov[1].id] = results[1].count;
        obj[pov[2].id] = results[2].count;
        return obj;
      })
    );

  }

  resolvePovCounter(pov: CustomerPovs | AdvisorPovs, isVirtualFA: boolean = false, filters: any) {

    switch (pov) {
      case 'advisor':
        return this.fetchAdvisorLoginCounter(filters);
      case 'customer':
        return isVirtualFA ? this.fetchCustomerLoginCounterVA(filters) : this.fetchCustomerLoginCounter(filters);
      case 'letture':
        return isVirtualFA ? this.fetchCustomerReadCounterVA(filters) : this.fetchCustomerReadCounter(filters);
      case 'letture_advisor':
        return this.fetchAdvisorReadCounter(filters);
      case 'letture_advisor_customer':
        return this.fetchAdvisorCustomerReadCounter(filters);
      case 'registrazioni':
        return isVirtualFA ? this.fetchCustomerSignupCounterVA(filters) : this.fetchCustomerSignupCounter(filters);
    }
  }

  resolvePovGraph(pov: CustomerPovs | AdvisorPovs, filters: MonitoringFilters = NO_FILTERS, isVirtualFA: boolean = false) {
    switch (pov) {
      case 'advisor':
        return this.fetchAdvisorLoginGraph(filters);
      case 'customer':
        return isVirtualFA ? this.fetchCustomerLoginGraphVA(filters) : this.fetchCustomerLoginGraph(filters);
      case 'letture':
        return isVirtualFA ? this.fetchCustomerReadGraphVA(filters) : this.fetchCustomerReadGraph(filters);
      case 'letture_advisor':
        return this.fetchAdvisorReadGraph(filters);
      case 'letture_advisor_customer':
        return this.fetchAdvisorCustomerReadGraph(filters);
      case 'registrazioni':
        return isVirtualFA ? this.fetchCustomerSignupGraphVA(filters) : this.fetchCustomerSignupGraph(filters);
    }
  }

  resolvePovDetails(pov: CustomerPovs | AdvisorPovs, filters: MonitoringFilters = NO_FILTERS, isVirtualFA: boolean = false) {

    switch (pov) {
      case 'advisor':
        return this.fetchAdvisorLoginDetails(filters);
      case 'customer':
        return isVirtualFA ? this.fetchCustomerLoginDetailsVA(filters) : this.fetchCustomerLoginDetails(filters);
      case 'letture':
        return isVirtualFA ? this.fetchCustomerReadDetailsVA(filters) : this.fetchCustomerReadDetails(filters);
      case 'letture_advisor':
        return this.fetchAdvisorReadDetails(filters);
      case 'letture_advisor_customer':
        return this.fetchAdvisorCustomerReadDetails(filters);
      case 'registrazioni':
        return isVirtualFA ? this.fetchCustomerSignupDetailsVA(filters) : this.fetchCustomerSignupDetails(filters);
    }
  }

  resolvePovDetailsForCSV(pov: CustomerPovs | AdvisorPovs, filters: MonitoringFilters = NO_FILTERS, isVirtualFA: boolean = false): Observable<any> {

    switch (pov) {
      case 'advisor':
        return this.fetchAdvisorLoginDetails(filters);
      case 'customer':
        return isVirtualFA ? this.fetchCustomerLoginDetailsVA(filters) : this.fetchCustomerLoginDetails(filters);
      case 'letture':
        return isVirtualFA ? this.fetchCustomerReadDetailsVA(filters) : this.fetchCustomerReadDetails(filters);
      case 'letture_advisor':
        return this.fetchAdvisorReadDetails(filters);
      case 'letture_advisor_customer':
        return this.fetchAdvisorCustomerReadDetails(filters);
      case 'registrazioni':
        return isVirtualFA ? this.fetchCustomerSignupDetailsVA(filters) : this.fetchCustomerSignupDetails(filters);
    }
  }

  fetchVAAdvisorsList(): Observable<Array<{ cod: string, description: string }>> {
    return this.http.post<Array<any>>(
      `${environment.serverUrl}/monitoring/advisors`,
      {}
    );
  }

  fetchAdvisorsCustomersList(): Observable<Array<{ customer: string, ndgCustomer: string }>> {
    return this.http.post<Array<any>>(
      `${environment.serverUrl}/monitoring/customers`,
      {}
    );
  }

}
