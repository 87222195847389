import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-option-date-monitoring',
  templateUrl: './option-date-monitoring.component.html',
  styleUrls: ['./option-date-monitoring.component.scss']
})
export class OptionDateMonitoringComponent implements OnInit {

  select: EventEmitter<string> = new EventEmitter();

  value: any;

  fromDate: Date = null;
  toDate: Date = null;
  singleDate: Date = null;

  constructor() { }

  ngOnInit() {
  }

  selectDate( date ) {
    this.value = date;
  }

  selectRange( from, to ) {
    this.value = {from, to };
  }

  selectPreset( type ) {
    this.value = type;
    this.singleDate = null;
  }

  setFromTo() {
    this.singleDate = null;
    this.value = null;
  }

  setSingleDate( date ) {
    this.value = '';
    this.fromDate = null;
    this.toDate = null;
  }

  apply() {

    if ( this.singleDate ) {
      this.select.emit( this.prettyDate(this.singleDate) );
      this.select.complete();
      return;
    }

    if ( this.value ) {
      this.select.emit( this.value );
      this.select.complete();
      return;
    }

    if ( this.fromDate && this.toDate ) {
      this.select.emit( this.prettyDate(this.fromDate) + ' - ' + this.prettyDate(this.toDate) );
      this.select.complete();
    }

    if ( this.value ) {
      this.select.emit( this.value );
      this.select.complete();
      return;
    }

  }

  isSingleDate() {
    return this.singleDate !== null && this.fromDate === null && this.toDate === null;
  }

  prettyDate( date: Date ) {
    let y: number | string = date.getFullYear();
    let m: number | string = date.getMonth() + 1;
    let d: number | string = date.getDate();

    if ( d < 10 ) {
      d = '0' + d;
    }

    if ( m < 10 ) {
      m = '0' + m;
    }

    return `${d}/${m}/${y}`;
  }

  get singleDatePretty() {
    if ( this.singleDate ) {
      return this.prettyDate(this.singleDate);
    }
    return null;
  }

}
