import { Component, OnInit, Input, InjectionToken, Inject, OnDestroy, Optional } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
export const PASSWORD_STRENGTH = new InjectionToken<any>('PASSWORD_STRENGTH');

@Component({
  selector: 'app-password-security',
  templateUrl: './password-security.component.html',
  styleUrls: ['./password-security.component.scss']
})
export class PasswordSecurityComponent implements OnInit {

  constructor(
    @Inject(PASSWORD_STRENGTH)
    @Optional()
    public score: Observable<number>
  ) { }

  score$: Observable<string>;
  lowSec$: Observable<boolean>;
  hiSec$: Observable<boolean>;



  ngOnInit(): void {
    if ( this.score ) {
      this.score$ = this.score.pipe(
        map( this.mapPasswordStrengthToString )
      );
    } else {
      this.score$ = of('UNKNOWN');
    }

  }

  mapPasswordStrengthToString( strength: number): string {
    switch (strength) {
      case 0:
      case 1:
        return 'bassa';
      case 2:
      case 3:
        return 'media';
      default:
        return 'alta';
    }
  }

}
