import { EventEmitter, Output, Component, OnDestroy, Input } from '@angular/core';

@Component({
    template: `
    <div>
        <i (click)="close.emit()" class="icon-close close"></i>
        <div class="modal-view">
            <i class="icon-exclamation-triangle-o"></i>
            <h2>Non hai ancora attivato il tuo account.</h2>
            <p>Accedi alla tua email ed utilizza il link per attivare il tuo account</p>

            <div *ngIf="emailSent" class="success-block" style="margin-bottom: 12px;">
                <i class="icon icon-exclamation-circle-o"></i>
                <span>
                L'email è stata inviata!
                </span>
            </div>

            <div *ngIf="error" class="error-block" style="margin-bottom: 12px;">
              <i class="icon icon-exclamation-circle-o"></i>
              <span>
                <b>Errore!</b>
                {{ error || "L'email inserita non è valida!" }}
              </span>
            </div>

            <pre *ngIf="error">{{error}}</pre>
            <button (click)="sendEmail.emit()" class="button is-small">Invia di nuovo</button>
            <span class="or">Oppure</span>
            <a class="link" (click)="showSupport.emit()">Hai bisogno di aiuto?</a>
        </div>
    </div>
    `,
    styleUrls: ['./style.scss']
})
export class LoginInactiveModalComponent implements OnDestroy {

    @Input()
    emailSent: any;

    @Input()
    error: any;

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    @Output()
    showSupport: EventEmitter<void> = new EventEmitter();

    @Output()
    sendEmail = new EventEmitter();

    ngOnDestroy(): void {
        this.close.complete();
        this.showSupport.complete();
    }

}
