import { Component, OnInit, InjectionToken, Inject, Optional } from '@angular/core';

export const TOOLTIP_TEXT = new InjectionToken<any>('TOOLTIP_TEXT');

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  constructor(
    @Inject(TOOLTIP_TEXT)
    public text: string
  ) { }

  ngOnInit() {
  }

}
