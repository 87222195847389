import { Action } from '@ngrx/store';

export enum ActionTypes {
    RequestActivation = '[Activation] Request activation',
    ActivationSuccess = '[Activation] Activation Success',
    ActivationError = '[Activation] Activation Error'
}

export class RequestActivationAction implements Action {
    readonly type = ActionTypes.RequestActivation;
    constructor( public payload: { token: string }) {}
}

export class ActivationSuccess implements Action {
    readonly type = ActionTypes.ActivationSuccess;
}

export class ActivationError implements Action {
    readonly type = ActionTypes.ActivationError;
    constructor( public payload: { error: string }) {}
}

export type Actions = RequestActivationAction | ActivationSuccess | ActivationError;
