import { Directive, HostBinding, Input, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appPlaceholder]'
})
export class FocusPlaceholderDirective implements OnInit {

  @Input('appPlaceholder')
  _placeholder: string;

  @HostBinding('placeholder')
  placeholder: any;

  constructor() { }

  ngOnInit(): void {
    this.placeholder = this._placeholder;
  }

  @HostListener('focus')
  onfocus() {
    this.placeholder = '';
  }

  @HostListener('blur')
  onblur() {
    this.placeholder = this._placeholder;
  }

}
