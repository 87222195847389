import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateAdapter, MatDatepicker } from "@angular/material";
import { isNullOrUndefined } from "util";
import { CustomHeader } from "../custom-header/custom-header";

@Component({
  selector: "app-single-date",
  templateUrl: "./single-date.component.html",
  styleUrls: ["./single-date.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SingleDateComponent,
    },
  ],
})
export class SingleDateComponent implements OnInit {
  @ViewChild(MatDatepicker) datePicker: MatDatepicker<Date>;
  @ViewChild("input") private _innerInput: ElementRef<HTMLInputElement>;
  @Input() placeholder: string;
  @Input() control: FormControl;
  customHeader = CustomHeader;

  @Input() min: Date;

  @Input() max: Date;

  select: EventEmitter<string> = new EventEmitter();
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  private _value: string;

  date: Date = null;
  multiYear: any;
  year: any;

  private selfClose: () => void;

  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnInit() {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  get value(): any {
    return this._value;
  }
  set value(v: any) {
    var prettyDate = this.prettyDate(v);
    this._value = prettyDate;
    this.onChange(this._value);
    this.onTouched();
  }

  writeValue(value: string) {
    this._value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  propagateChange = (_: any): void => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onOpen(datePicker) {
    if (isNullOrUndefined(this.selfClose)) {
      this.selfClose = datePicker.close;
    }
    var windowClickEvent = (e) => {
      if (
        !e.target.className.includes("input") &&
        !document.getElementById(datePicker.id).contains(e.target) &&
        !(this.multiYear && this.multiYear.contains(e.target)) &&
        !(this.year && this.year.contains(e.target))
      ) {
        onClose();
      }

      this.multiYear = document.querySelector("mat-multi-year-view");
      this.year = document.querySelector("mat-year-view");
    };
    window.addEventListener("click", windowClickEvent);

    datePicker.close = () => {};
    var onClose = () => {
      window.removeEventListener("click", windowClickEvent);
      datePicker.close = this.selfClose;
      this.selfClose = undefined;
      datePicker.close();
    };
    setTimeout(() => {
      var matCalendar = document.getElementById(datePicker.id);
      matCalendar.style.height = "auto";
      var annullaButton = document.createElement("button");
      var annullaText = document.createTextNode("Annulla");
      annullaButton.classList.add("btn", "btn-reset");
      annullaButton.appendChild(annullaText);
      annullaButton.addEventListener("click", () => {
        datePicker._datepickerInput.value = "";
        this.resetDate();
        onClose();
      });
      var confermaButton = document.createElement("button");
      var confermaText = document.createTextNode("Conferma");
      confermaButton.classList.add("btn", "btn-confirm");
      confermaButton.appendChild(confermaText);
      confermaButton.addEventListener("click", () => {
        onClose();
      });
      var calendarFooter = document.createElement("div");
      calendarFooter.classList.add("calendar-footer");

      calendarFooter.appendChild(annullaButton);
      calendarFooter.appendChild(confermaButton);
      matCalendar.appendChild(calendarFooter);
    }, 0);
  }

  selectDate(date) {
    this.value = date;
  }

  resetDate() {
    this.date = null;
    this._value = null;
    this.onChange(this._value);
  }

  prettyDate(date: Date) {
    let y: number | string = date.getFullYear();
    let m: number | string = date.getMonth() + 1;
    let d: number | string = date.getDate();

    if (d < 10) {
      d = "0" + d;
    }

    if (m < 10) {
      m = "0" + m;
    }

    return `${d}/${m}/${y}`;
  }
}
