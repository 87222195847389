import { Component, OnInit, Output, EventEmitter, InjectionToken, Inject, Optional, OnDestroy } from '@angular/core';
import { Customer } from '@app/models';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, delay, mergeMap, mergeMapTo, filter, map, catchError } from 'rxjs/operators';
import { MonitoringService } from '@app/monitoring/services/monitoring.service';
import { FormControl } from '@angular/forms';

export const SELECTED_ADVISORS = new InjectionToken('selected_advisors');

export type ADVISOR_ID = String;

@Component({
  selector: 'app-advisor-picker-monitoring',
  templateUrl: './advisor-picker-monitoring.component.html',
  styleUrls: ['./advisor-picker-monitoring.component.scss']
})
export class AdvisorPickerMonitoringComponent implements OnInit, OnDestroy {

  term: FormControl = new FormControl('');

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // customers$: Observable<Array<{cod:string,description:string}>>;

  shownResults$: Observable<Array<{cod: string, description: string}>>;

  selection: Array<any>;

  @Output()
  select: EventEmitter<Array<any>> = new EventEmitter();

  constructor(
    @Optional()
    @Inject(SELECTED_ADVISORS)
    public preselected: Array<any>,
    public monitoringService: MonitoringService
  ) { }

  ngOnInit() {

    if ( this.preselected ) {
      this.selection = [...this.preselected];
      //console.log('Customers already selected:');
      console.table( this.preselected );
    } else {
      this.selection = [];
    }

    this.shownResults$ = combineLatest(
      this.monitoringService.fetchVAAdvisorsList().pipe(
        // catchError( () => {
        //   return of([{cod: '0', description: 'Noname'}, { cod: '1', description: 'Noname1'}]);
        // })
      ),
      this.term.valueChanges.pipe(
        delay(300)
      )
    ).pipe(
      tap( x => this.loading$.next(true) ),
      // delay( 120 ),
      map(
        arrayAndTerm => {

          const array = arrayAndTerm[0];
          const term = arrayAndTerm[1].toUpperCase();

          if ( (term as string).length === 0 ) {
            return [];
          }

          const result = array.filter( (v, i) => {
            return v.description.toUpperCase().includes(term); // v.cod.toUpperCase().includes(term) || 
          });
          return result;
        }
      ),
      tap( x => this.loading$.next(false) ),
    );

    // of(0).pipe(
    //   tap(
    //     x => this.loading$.next(true)
    //   ),
    //   mergeMapTo( this.monitoringService.fetchVAAdvisorsList() ),
    //   tap(
    //     x => this.loading$.next(false)
    //   )
    // ).subscribe();

    // this.loading$.next( true )

    // this.customers$ = of(
    //   [
    //     { name: 'a', id: 'RU091' },
    //     { name: 'b', id: 'RU092' },
    //     { name: 'c', id: 'RU093' },
    //     { name: 'd', id: 'RU094' },
    //     { name: 'e', id: 'RU095' },
    //   ]
    // );

    

  }

  selectCustomer( customer: any ) {
    // //console.log(customer);
    // //console.log("selecting customer")

    const match = this.selection.filter( x => {
      return x.cod === customer.cod;
    });

    if ( match.length > 0 ) {
      const idx = this.selection.indexOf( match[0] );
      this.selection.splice(idx, 1);
    } else {
      // //console.log("selecting")
      this.selection.push( customer );
    }

  }

  isSelected( customer: any ) {
    // //console.log(`Is customer ${customer.description} selected?`);
    if ( this.selection && this.selection.length !== 0) {
      const isSelected = this.selection.filter( x => {
        return x.cod === customer.cod;
      });

      return isSelected.length > 0;

    }

    // //console.log(`No. ( Selection.length === 0 )`);
    return false;
  }

  cancel() {
    this.select.emit( [] );
    this.select.complete();
  }

  applySelection() {
    this.select.emit( this.selection );
    this.select.complete();
  }

  ngOnDestroy() {
    //console.log("picker destroyed!");
  }

}
