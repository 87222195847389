import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-password-gauge',
  templateUrl: './password-gauge.component.html',
  styleUrls: ['./password-gauge.component.scss']
})
export class PasswordGaugeComponent implements OnInit {

  @Input() strength: Observable<number>;

  securityLevel$: Observable<number[]>;

  constructor() { }

  ngOnInit(): void {
    this.securityLevel$ = this.strength.pipe(
      map( level => {
        const arr = Array(4).fill(0);
        for (const number of [0, 1, 2, 3]) {
          if ( level > number ) {
            arr[number] = 1;
          }
        }
        return arr;
      })
    );
  }

}
