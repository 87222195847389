import { initialMobileState, State } from './mobile.state';
import { Actions, ActionTypes } from './mobile.actions';

export function mobileReducer(state: State = initialMobileState, action: Actions) {
    switch (action.type) {
        case ActionTypes.IsMobile:
            return { ...state, isMobile: action.payload.isMobile }

        case ActionTypes.fromApp: 
            return { ...state, fromApp: action.payload.fromApp }
        default:
            return state;
    }
}
