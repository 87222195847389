import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { RequestActivationAction } from '../store/activation/activation.actions';

import { RootStore } from '../store';
import { selectError, selectErrorMessage, selectIsLoading } from '../store/activation/activation.selectors';

import { Observable } from 'rxjs';

@Component({
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivatePageComponent implements OnInit {

    loading$: Observable<boolean>;
    error$: Observable<boolean>;
    message$: Observable<string>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<RootStore>
    ) { }

    ngOnInit() {

        this.loading$ = this.store.select( selectIsLoading );

        this.error$ = this.store.select( selectError );
        this.message$ = this.store.select( selectErrorMessage );


        const params = this.route.snapshot.queryParamMap;

        if ( !params.has('token') ) {
            this.router.navigateByUrl('/login');
            return;
        }

        this.store.dispatch( new RequestActivationAction({ token: params.get('token')}) );

    }

}
