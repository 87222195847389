// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environment: 'quality',

    serverUrl: 'https://api.datlas-qa.it/allianzbank/mypost/v1.1',
    keyId: '921929e8-068b-49d7-9027-e7692895e20f',
    claimsPrefix: 'https://projectone.datlas-qa.it',
    redirectToApp: 'https://testservizi.allianzbank.it/MobileAS/MenuI.sec?menu=homeI'
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
