import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    templateUrl: './message.component.html',
    styleUrls: [
        './message.component.scss'
    ]
})
export class MessageComponent implements OnInit {

    message: string;
    title: string;

    constructor(
        private cookie: CookieService
    ) {}

    ngOnInit() {

        // tslint:disable-next-line:max-line-length
        // this.message = this.b64DecodeUnicode('QVRURU5aSU9ORSDigJMgQWNjZXNzbyBuZWdhdG8gLSBHZW50aWxlIENsaWVudGUsIHBlciBs4oCZYWNjZXNzbyBhbCBzZXJ2aXppbyDDqCBuZWNlc3NhcmlvIGF2ZXIgcmljaGllc3RvLCB0cmFtaXRlIE1vZHVsbyBVbmljbywgZGkgdm9sZXIgcmljZXZlcmUgbGUgQ29tdW5pY2F6aW9uaSBkZWxsYSBCYW5jYSBpbiBmb3JtYXRvIE5PTiBjYXJ0YWNlby4gUGVyIGNoaWFyaW1lbnRpLCBlZCBhdHRpdmF6aW9uZSBkZWwgc2Vydml6aW8sIExhIGludml0aWFtbyBnZW50aWxtZW50ZSBhIGNvbnRhdHRhcmUgaWwgU3VvIENvbnN1bGVudGUgRmluYW56aWFyaW8gQWxsaWFueiBCYW5rLg==');

        this.message = this.b64DecodeUnicode( this.cookie.get('mypost_error_description') );
        this.title = this.b64DecodeUnicode( this.cookie.get('mypost_error_title') );

        this.cookie.delete('mypost_error_description');
        this.cookie.delete('mypost_error_title');
    }

    // decode(s) {
	// 	for ( let a, b, i = -1, l = (s = s.split('')).length, o = String.fromCharCode, c = 'charCodeAt'; ++i < l;
	// 		((a = s[i][c](0)) & 0x80) &&
	// 		(s[i] = (a & 0xfc) === 0xc0 && ((b = s[i + 1][c](0)) & 0xc0) === 0x80 ?
	// 		o(((a & 0x03) << 6) + (b & 0x3f)) : o(128), s[++i] = '')
	// 	) { }
	// 	return s.join('');
    // }

    b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

}
