import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { RootStore } from "./store/store.module";

import { ActivatePageComponent } from "./activate/activate.component";
import { SharedModule } from "./shared/shared.module";
import { environment } from "src/environments/environment";
import { KeyIdInterceptor } from "./configuration/interceptors/key-id.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./configuration/interceptors/token.interceptor";
import { ChildFeatureGuard } from "./configuration/guards/permission";

import { NgxMaskModule } from "ngx-mask";
import { registerLocaleData } from "@angular/common";

import localeIt from "@angular/common/locales/it";
import { CookieService } from "ngx-cookie-service";
import { HasValidResetTokenGuard } from "./configuration/guards/has-reset-token";
import { MessageComponent } from "./message/message.component";
import { HasErrorMessageGuard } from "./configuration/guards/has-error-message";
import { VirtualFaInterceptor } from "./configuration/interceptors/virtual-fa.interceptor";

// IT LOCALE
registerLocaleData(localeIt, "it-IT");

@NgModule({
  declarations: [AppComponent, ActivatePageComponent, MessageComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,

    NgxMaskModule.forRoot(),

    // STATE
    RootStore,
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: environment.production,
    }),
  ],
  providers: [
    CookieService,
    ChildFeatureGuard,
    HasErrorMessageGuard,
    HasValidResetTokenGuard,

    { provide: LOCALE_ID, useValue: "it-IT" },
    // Overriding Default MatDatepicker Locale
    { provide: MAT_DATE_LOCALE, useValue: "it-IT" },

    // App wide interceptors
    { provide: HTTP_INTERCEPTORS, useClass: KeyIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VirtualFaInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
