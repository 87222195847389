import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { mobileReducer } from './mobile.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('mobile', mobileReducer)
  ]
})
export class MobileStoreModule { }
