import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { RootStoreState } from '@app/store';
import { selectIsMobile } from '@app/store/mobile/mobile.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTagsComponent implements OnInit {

  @Input()
  filters: any;

  @Input()
  filterCount: number;

  @Output()
  remove = new EventEmitter<string>();

  @Output()
  reset = new EventEmitter();

  isMobile$ = this.store.select( selectIsMobile );

  constructor(private store: Store<RootStoreState.State>) { }

  ngOnInit() {
  }

  removeFilter( filterKey ) {
    this.remove.emit( filterKey );
  }

  resetFilters() {
    this.reset.emit();
  }

}
