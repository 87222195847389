import { Directive, ContentChildren, QueryList, ElementRef, OnInit, ViewChildren, ViewChild, AfterViewInit } from '@angular/core';
import { FocusStepDirective } from './focus-step.directive';

@Directive({
  selector: '[appFocusWalker]',
  queries: {
    steps: new ContentChildren(FocusStepDirective)
  }
})
export class FocusWalkerDirective implements AfterViewInit {

  steps: QueryList<FocusStepDirective>;

  constructor() { }

  ngAfterViewInit(): void {
    this.steps.first.filled.subscribe(
      (ev) => {
        this.steps.toArray()[1].element.nativeElement.focus();
      }
    );
  }

}
