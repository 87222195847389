import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    template: `
        <div>
            <i (click)="close.emit()" class="icon-close close"></i>
            <div class="modal-view">
                <i class="icon-exclamation-circle-o red"></i>
                <h2>Attenzione</h2>
                <p>Login momentaneamente non disponibile, i nostri tecnici stanno lavorando per ripristinare il servizio.</p>
                <!--<p>[400 || 5XX]</p>-->
            </div>
        </div>
    `,
    styleUrls: ['./style.scss']
})
export class LoginErrorModalComponent {

    @Output()
    close: EventEmitter<void> = new EventEmitter();

}
