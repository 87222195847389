import { State } from './activation.state';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { stat } from 'fs';

export const getIsLoading = ( state: State ) => state.isLoading;

export const getError = ( state: State ) => state.error;

export const selectActivationState: MemoizedSelector<object, State> =
    createFeatureSelector('activation');

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
    selectActivationState,
    getIsLoading
);

export const selectError: MemoizedSelector<object, boolean> = createSelector(
    selectActivationState,
    getError
);

export const selectErrorMessage: MemoizedSelector<object, string> = createSelector(
    selectActivationState,
    ( state: State ) => state.message
);

