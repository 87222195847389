import { Action } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { VirtualOperator } from "./auth.effects";

export enum ActionTypes {
  Login = "[Auth] Login",
  SSOLogin = "[Auth] SSO Login",
  Logout = "[Auth] Logout",
  LoginSuccess = "[Auth] Login success",
  LoginFailed = "[Auth] Login failed",
  Activate = "[Auth] Activate",
  ActivationCompleted = "[Auth] Activation Completed",
  ActivationError = "[Auth] Activation Error",
  FeatureChecked = "[Auth] Feature Checked",
  FetchUserData = "[Auth] Fetch user data",
  FetchUserDataSuccess = "[Auth] Fetch user data success",
  FetchUserDataError = "[Auth] Fetch user data error",
  SwitchAdvisorCode = "[Auth] SwitchAdvisorCode",
  FetchVaCodes = "[ Auth ] FetchVaCodes",
  FetchVaCodesSuccess = "[ Auth ] FetchVaCodesSuccess",
}

export class FetchVaCodesSuccess implements Action {
  readonly type = ActionTypes.FetchVaCodesSuccess;
  constructor(public payload: { codes: Array<VirtualOperator> }) {}
}

export class FetchVaCodes implements Action {
  readonly type = ActionTypes.FetchVaCodes;
  constructor(public payload: { query: string }) {}
}

export class SwitchToAdvisorCode implements Action {
  readonly type = ActionTypes.SwitchAdvisorCode;
  constructor(public payload: { code: string }) {}
}

export class Login implements Action {
  readonly type = ActionTypes.Login;
  constructor(
    public payload: { credentials: { username: string; password: string } }
  ) {}
}

export class LoginSuccess implements Action {
  readonly type = ActionTypes.LoginSuccess;
  constructor(
    public payload: {
      token: string;
      id: any;
      redirect?: boolean;
      isFromApp?: boolean;
      isMobile?: boolean;
    }
  ) {}
}

export class SSOLogin implements Action {
  readonly type = ActionTypes.SSOLogin;
  constructor(
    public payload: {
      token: string;
      id: any;
      redirect?: boolean;
    }
  ) {}
}

export class LoginFailure implements Action {
  readonly type = ActionTypes.LoginFailed;
  constructor(public payload: HttpErrorResponse) {}
}

export class Logout implements Action {
  readonly type = ActionTypes.Logout;
}

export class Activate implements Action {
  readonly type = ActionTypes.Activate;
  constructor(public payload: { token: string }) {}
}

export class ActivationComplete implements Action {
  readonly type = ActionTypes.ActivationCompleted;
}

export class ActivationError implements Action {
  readonly type = ActionTypes.ActivationError;
  constructor(public payload: { error: string }) {}
}

export class FetchUserData implements Action {
  readonly type = ActionTypes.FetchUserData;
}

export class FetchUserDataSuccess implements Action {
  readonly type = ActionTypes.FetchUserDataSuccess;
  constructor(public payload: any) {}
}

export class FetchUserDataError implements Action {
  readonly type = ActionTypes.FetchUserDataError;
}

export class FeatureChecked implements Action {
  readonly type = ActionTypes.FeatureChecked;
}

export type Actions =
  | Login
  | SSOLogin
  | Logout
  | LoginSuccess
  | LoginFailure
  | Activate
  | ActivationError
  | ActivationComplete
  | FetchUserData
  | FetchUserDataSuccess
  | FetchUserDataError
  | SwitchToAdvisorCode
  | FetchVaCodes
  | FetchVaCodesSuccess
  | FeatureChecked;
