import { ReplaySubject } from 'rxjs';
export class DetectMobileService {
  private matches = new ReplaySubject<boolean>(1);
  public match$ = this.matches.asObservable();

  constructor(public readonly query: string) {
    if (window) {
      const mediaQueryList = window.matchMedia(this.query);
      const listener = (event: any) => this.matches.next(event.matches);
      listener(mediaQueryList);

      const browser = this.getBrowser(); 

      if (browser && (browser.name === 'Safari' && browser.version < 14) || ((browser.name === 'Chrome' || browser.name === 'CriOS') && browser.version <= 92)) {
        mediaQueryList.addListener(listener); 
      } else {
        mediaQueryList.addEventListener('change', listener);
      }
    }
  }

  private getBrowser(): { name: string, version: number } {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|crios|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: parseInt((tem[1] || ''), 10) };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) { return { name: 'Opera', version: parseInt(tem[1], 10) }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: parseInt(M[1], 10)
    };
  }
}
