import { MemoizedSelector, createSelector, createFeatureSelector } from '@ngrx/store';
import { State } from './auth.state';
import { Customer } from 'src/app/models';

import { environment } from 'src/environments/environment';
import { VirtualOperator } from './auth.effects';

export const DATA_FIELD = 'user_metadata';
export const FEATURES_FIELD = 'features';



// DERIVED STATE SELECTORS
// export const getUser = (state: State) => state.user;

export const getIsActivating = ( state: State ) => state.isActivating;

export const getToken = ( state: State ) => state.token;

export const selectAuth: MemoizedSelector<object, State>
    = createFeatureSelector<State>('auth');

export const selectIsActivating: MemoizedSelector<object, boolean> = createSelector(
    selectAuth,
    getIsActivating
);

export const selectActivationSuccess: MemoizedSelector<object, boolean> = createSelector(
    selectAuth,
    ( state: State ) => state.activationCompleted
);

export const selectActivationError: MemoizedSelector<object, string> = createSelector(
    selectAuth,
    ( state: State ) => state.activationError
);

export const selectToken: MemoizedSelector<object, string> = createSelector(
    selectAuth,
    getToken
);

export const selectUserData: MemoizedSelector<object, any> = createSelector(
    selectAuth,
    ( state: State ) => {
        //console.log('GETTING DATA FROM ');
        //console.log(`${environment.claimsPrefix}/${DATA_FIELD}`);
        // console.log(state);
        return state.userData ? state.userData[`${environment.claimsPrefix}/${DATA_FIELD}`] : {};
    }
);

export const selectProfileData: MemoizedSelector<object, Customer> = createSelector(
    selectAuth,
    ( state: State ) => state.profileData
);

export const selectUserFeatures: MemoizedSelector<object, string[]> = createSelector(
    selectAuth,
    ( state: State ) => {
        if ( state.userData ) {
            return state.userData[`${environment.claimsPrefix}/${FEATURES_FIELD}`];
        }
        return [];
    }
);

export const selectLoginError: MemoizedSelector<object, boolean> = createSelector(
    selectAuth,
    ( state: State ) => state.loginError
);

export const isVirtualAdvisor: MemoizedSelector<object, boolean> = createSelector(
    selectUserData,
    ( data: any ) => {
        //console.log('virtual fa');
        // console.log(data);
        return data.virtualFA;
    }
);

export const selectPickedVaCode: MemoizedSelector<object, string> = createSelector(
    selectAuth,
    ( state: State ) => state.pickedVaCode
);

export const selectFetchedVACodeList: MemoizedSelector<object, VirtualOperator[] > = createSelector(
    selectAuth,
    ( state: State ) => state.vaCodes
);


export const selectLoadingVaCodeList: MemoizedSelector<object, boolean > = createSelector(
    selectAuth,
    ( state: State ) => state.loadingVaCodes
);


