import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appNoPaste]'
})
export class NoPasteDirective {

  /**
   * Prevents pasting on an input field
   */
  @HostListener('paste', ['$event'])
  onPaste( $event: Event) {
    $event.preventDefault();
  }

}
