import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from "@angular/core";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { MatCalendar, MatDatepickerIntl } from "@angular/material/datepicker";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/** Custom header component for datepicker. */
@Component({
  selector: "custom-header",
  styleUrls: ["./custom-header.scss"],
  template: `
    <div class="custom-label">Seleziona data</div>
    <div class="current-date">
      {{ this._calendar.activeDate | date : "EEE, MMM yyyy" }}
    </div>
    <div class="custom-header">
      <span
        class="custom-header-label {{
          _calendar.currentView == 'month' ? 'close' : 'open'
        }}"
        (click)="
          _calendar.currentView =
            _calendar.currentView == 'month' ? 'multi-year' : 'month'
        "
      >
        {{ this._calendar.activeDate | date : "MMMM yyyy" }}
      </span>

      <button
        class="mat-calendar-previous-button mat-icon-button"
        mat-icon-button
        (click)="previousClicked('month')"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button
        class="mat-calendar-next-button mat-icon-button"
        mat-icon-button
        (click)="nextClicked('month')"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeader<D extends Date = Date> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    public _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    @Inject(DateAdapter) public _adapter: DateAdapter<D>,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  get selectedDate() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.dateInput)
      .toLocaleUpperCase();
  }

  previousClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
