import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ActionTypes, RequestActivationAction, ActivationSuccess, ActivationError } from './activation.actions';
import { mapTo, map, mergeMap, delay, tap, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface ActivationResponse {
    success: boolean;
    error: string;
}

@Injectable()
export class ActivationEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
    ) {}

    @Effect()
    activationRequests$: Observable<Action> = this.actions$.pipe(
        ofType<RequestActivationAction>( ActionTypes.RequestActivation ),
        // EFFECT
        // tap(
        //     x => {
        //         //console.log("EFFECTS ARE ACTING ON REQUEST ACTIVATION");
        //     }
        // ),
        mergeMap(
            x => this.activate( x.payload.token )
        ),
        map(
            response => {
                return new ActivationSuccess();
                // if ( response.success ) {
                //     return new ActivationSuccess();
                // }
                // return new ActivationError({
                //     error: response.error
                // });
            }
        ),
        catchError( x => of( new ActivationError( {error: x.error.error.message})))
    );

    activateAccount(token: string): Observable<ActivationResponse> {
        return of({ success: false, error: 'Il token fornito non è valido o è scaduto' }).pipe(
            delay(11280)
        );
    }

    activate( token: String ): Observable<any> {
        return this.http.get(
            `${environment.serverUrl}/customers/signup/activation/${token}`
        );
    }

}
