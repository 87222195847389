import { Directive, Input, OnInit, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';


@Directive({
  selector: '[appFocusStep]'
})
export class FocusStepDirective implements OnInit {

  @Input() appFocusStep: number;
  @Output() filled: EventEmitter<string>;

  constructor(public element: ElementRef<HTMLInputElement>) { }

  @HostListener('input')
  onKeyUp(): void {
    // //console.log("[Focus Step] Change");
    // //console.log(this.appFocusStep);
    // //console.log(this.element.nativeElement.value);
    if ( this.element.nativeElement.value.length >= this.appFocusStep ) {
      this.filled.emit('next');
    }
  }

  ngOnInit(): void {
    // //console.log("[Focus Step] Init");
    this.filled = new EventEmitter();

  }

}
