import { Component, OnInit, Input, Sanitizer, SecurityContext, TemplateRef, ViewContainerRef, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { State } from '@app/store/root-state';
import {isVirtualAdvisor, selectUserData, selectUserFeatures} from '@app/store/auth/auth.selectors';
import { Observable, of } from 'rxjs';
import { tap, map, take, takeWhile, pluck } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { selectIsMobile } from '@app/store/mobile/mobile.selectors';

export interface Breadcrumb {
  title: string;
  url?: string;
  width?: number;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  logo: string = null;

  @Input()
  header: string | Breadcrumb[] = 'MyPost-Investment';

  isMobile$ = this.store.select( selectIsMobile );

  canGoToMonitoring$: Observable<boolean>;

  userFeatures: Observable<Array<string>>;

  isVirtualAdvisor$: Observable<boolean>;

  alive = true;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private store: Store<State>
  ) { }

  ngOnInit() {

    this.userFeatures = this.store.select( selectUserFeatures );

    this.isVirtualAdvisor$ = this.store.select( isVirtualAdvisor );
  }


  hasBreadcrumbs() {
    return typeof this.header !== 'string';
  }

  goto( url ) {
    if ( url ) {
      this.router.navigateByUrl( url );
    }
  }

  canGoToMonitoring() {
    return true;
  }

  goHome() {

    this.userFeatures.pipe(
      map(
        features => {
          if ( features.includes('homeFinancialAdvisor') ) {
            return '/financial-advisor';
          } else {
            return '/customer';
          }
        }
      ),
      take(1),
      tap(
        url => {
          this.router.navigateByUrl(url);
        }
      )
    ).subscribe();

  }

  goToMonitoring() {
    this.router.navigateByUrl('/monitoring');
  }
}
