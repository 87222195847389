import { Action } from "@ngrx/store";

export enum ActionTypes {
    IsMobile = '[Mobile] Is Mobile',
    fromApp = '[Mobile] From App'
}

export class IsMobile implements Action {
    readonly type = ActionTypes.IsMobile;
    constructor( public payload: { isMobile: boolean } ) {}
}

export class FromApp implements Action {
    readonly type = ActionTypes.fromApp;
    constructor( public payload: { fromApp: boolean } ) {}
}

export type Actions = IsMobile | FromApp;
