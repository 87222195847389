import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent implements OnInit {
  @Input()
  mobile = false;

  @Input()
  logoWidth = 180;

  @Input()
  logoHeight = 80;

  constructor() { }

  ngOnInit() {
  }

}
