import { Component, OnInit, Output, EventEmitter, InjectionToken, Inject, Optional, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, delay, map } from 'rxjs/operators';
import { MonitoringService } from '@app/monitoring/services/monitoring.service';
import { FormControl } from '@angular/forms';

export const SELECTED_CUSTOMERS = new InjectionToken('selected_customers');

export type CUSTOMER_ID = String;

@Component({
  selector: 'app-multi-customer-picker',
  templateUrl: './multi-customer-picker.component.html',
  styleUrls: ['./multi-customer-picker.component.scss']
})
export class MultiCustomerPickerComponent implements OnInit, OnDestroy {

  term: FormControl = new FormControl('');

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  customers$: Observable<Array<any>>;

  shownResults$: Observable<Array<any>>;

  selection: Array<any>;

  @Output()
  select: EventEmitter<Array<any>> = new EventEmitter();

  constructor(
    @Optional()
    @Inject(SELECTED_CUSTOMERS)
    public preselected: Array<any>,
    public monitoringService: MonitoringService
  ) { }

  ngOnInit() {

    if ( this.preselected ) {
      this.selection = [...this.preselected];
      // //console.log('Customers already selected:');
      // console.table( this.preselected );
    } else {
      this.selection = [];
    }

    // this.term.valueChanges.subscribe(
    //   x => //console.log(x)
    // );

    this.shownResults$ = combineLatest(
      this.monitoringService.fetchAdvisorsCustomersList(),
      this.term.valueChanges.pipe(
        delay(300)
      )
    ).pipe(
      tap( () => this.loading$.next(true) ),
      map(
        arrayAndTerm => {
          // //console.log("ARRAY AND TERMS");
          const array = arrayAndTerm[0];
          const term = arrayAndTerm[1].toUpperCase();
          // //console.log(array, term);

          if ( (term as string).length === 0 ) {
            // //console.log("TERM IS len 0");
            return [];
          }

          const result = array.filter( (v) => {
            return v.customer.toUpperCase().includes(term); // v.cod.toUpperCase().includes(term) ||
          });
          // //console.log("VALUES");
          // //console.log(result);
          return result;
        }
      ),
      tap( () => this.loading$.next(false) ),
    );


    // of(0).pipe(
    //   tap(
    //     x => this.loading$.next(true)
    //   ),
    //   mergeMapTo( this.monitoringService.fetchAdvisorsCustomersList() ),
    //   tap(
    //     x => this.loading$.next(false)
    //   )
    // ).subscribe();

    // this.loading$.next( true )

    // this.customers$ = of(
    //   [
    //     { name: 'a', id: 'RU091' },
    //     { name: 'b', id: 'RU092' },
    //     { name: 'c', id: 'RU093' },
    //     { name: 'd', id: 'RU094' },
    //     { name: 'e', id: 'RU095' },
    //   ]
    // );


  }

  selectCustomer( customer: any ) {
    const match = this.selection.filter( x => {
      return x.customer === customer.customer;
    });

    if ( match.length > 0 ) {
      const idx = this.selection.indexOf( match[0] );
      this.selection.splice(idx, 1);
    } else {
      // //console.log("selecting")
      this.selection.push( customer );
    }
  }

  isSelected( customer: any ) {
    if ( this.selection && this.selection.length !== 0) {
      const isSelected = this.selection.filter( x => {
        return x.customer === customer.customer;
      });

      return isSelected.length > 0;

    }

    return false;
  }

  cancel() {
    this.select.emit( [] );
    this.select.complete();
  }

  applySelection() {
    this.select.emit( this.selection );
    this.select.complete();
  }

  ngOnDestroy() {
    //console.log("picker destroyed!");
  }

}
