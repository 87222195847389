import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    template: `
        <div>
            <i (click)="close.emit()" class="icon-close close"></i>
            <div class="modal-view">
                <i class="icon-error"></i>
                <h2>Siamo Spiacenti</h2>
                <p>Non è possibile accogliere la richiesta.</p>
                <!--( NOT_FOUND_IN_ARCO )-->
            </div>
        </div>
    `,
    styleUrls: ['./style.scss']
})
export class RegistrationNotAllowedModalComponent {

    @Output()
    close: EventEmitter<void> = new EventEmitter();
}
