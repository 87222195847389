import { State, initialState } from './activation.state';
import { Actions, ActionTypes } from './activation.actions';

export function activationReducer( state: State = initialState, action: Actions ): State {

    switch (action.type) {
        // case ActionTypes.RequestActivation:

        //     //console.log("Request Activation is reducing");

        //     return {
        //         ...state,
        //         isLoading: true
        //     };
        case ActionTypes.ActivationError:
            return {
                ...state,
                isLoading: false,
                message: action.payload.error,
                success: false,
                error: true
            };
        case ActionTypes.ActivationSuccess:
            return {
                ...state,
                isLoading: false,
                message: null,
                success: true,
                error: false
            };
        default:
            return state;
    }

}
