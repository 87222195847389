import { Action, Store } from "@ngrx/store";
import { State } from "@app/store/root-state";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  ActionTypes,
  LoginFailure,
  LoginSuccess,
  ActivationComplete,
  ActivationError,
  Activate,
  Login,
  FetchUserDataSuccess,
  FetchUserDataError,
  FetchUserData,
  SSOLogin,
  FetchVaCodes,
  FetchVaCodesSuccess,
} from "./auth.actions";
import {
  delay,
  map,
  mergeMap,
  catchError,
  tap,
  mapTo,
  exhaustMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { Router } from "@angular/router";

import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { FEATURES_FIELD } from "./auth.selectors";
import { selectIsFromApp, selectIsMobile } from "../mobile/mobile.selectors";

export interface AuthenticationResponse {
  access_token: string;
  id_token: string;
}

export interface VirtualOperator {
  name: string;
  surname: string;
  code: string;
}

@Injectable()
export class AuthEffects {
  jwtDecoder: JwtHelperService;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store<State>
  ) {
    this.jwtDecoder = new JwtHelperService();
  }

  @Effect()
  fetchVaCode$: Observable<Action> = this.actions$.pipe(
    ofType<FetchVaCodes>(ActionTypes.FetchVaCodes),
    switchMap((action) => {
      return this.fetchVa(action.payload.query);
    }),
    map((x) => new FetchVaCodesSuccess({ codes: x })),
    catchError((err, c) => {
      return of(new FetchVaCodesSuccess({ codes: [] }));
    })
  );

  @Effect()
  activation$: Observable<Action> = this.actions$.pipe(
    ofType<Activate>(ActionTypes.Activate),
    mergeMap((action) => {
      // DO ACTIVATE USING A SERVICE
      return this.activate(action.payload.token).pipe(
        map((x) => {
          if (x.success) {
            return new ActivationComplete();
          }
          return new ActivationError({ error: x.error });
        }),
        catchError((err) =>
          of(
            new ActivationError({
              error: "Errore nell'attivazione dell'account",
            })
          )
        )
      );
    })
  );

  @Effect()
  ssoLogin$: Observable<Action> = this.actions$.pipe(
    ofType<SSOLogin>(ActionTypes.SSOLogin),
    map((x) => {
      const token = x.payload.token;
      const data = this.decodeJWTToken(x.payload.id);
      return new LoginSuccess({
        token,
        id: data,
        redirect: x.payload.redirect,
      });
    })
  );

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType<Login>(ActionTypes.Login),
    withLatestFrom(this.store),
    exhaustMap(([action, options]) => {
      // return this.asyncMock(action.payload.credentials).pipe(
      return this.doLogin(action.payload.credentials).pipe(
        map((x) => {
          const token = x.access_token;
          const data = this.decodeJWTToken(x.id_token);
          return new LoginSuccess({
            token,
            id: data,
            redirect: true,
            ...options.mobile,
          });
        }),
        // map( data => new LoginSuccess() ),
        catchError((error: HttpErrorResponse) => {
          //console.log(error);
          return of(new LoginFailure(error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  loginSuccess$: Observable<any> = this.actions$.pipe(
    ofType<LoginSuccess>(ActionTypes.LoginSuccess),
    tap((n) => {
      // NOT ELEGANT AT ALL..
      if (n.payload.redirect) {
        // //console.log('I\'m going home!');
        this.router.navigateByUrl(this.getPageToRedirect(n));
      }
    })
  );

  @Effect({ dispatch: false })
  logout$: Observable<any> = this.actions$.pipe(
    ofType(ActionTypes.Logout),
    tap((action) => {
      this.router.navigateByUrl("/login");
    })
  );

  @Effect()
  fetchUserData$: Observable<Action> = this.actions$.pipe(
    ofType<FetchUserData>(ActionTypes.FetchUserData),
    exhaustMap((fetchCommand) => {
      return this.fetchUserData().pipe(
        // tap(//console.log),
        map((data) => new FetchUserDataSuccess(data)),
        catchError((err) => of(new FetchUserDataError()))
      );
    })
  );

  @Effect()
  featureCheckedEffect$: Observable<Action> = this.actions$.pipe(
    ofType<FetchUserData>(ActionTypes.FeatureChecked),
    exhaustMap(() => {
      return of()
    })
  );

  doLogin(credentials: {
    username: string;
    password: string;
  }): Observable<AuthenticationResponse> {
    return this.http.post<AuthenticationResponse>(
      `${environment.serverUrl}/login`,
      credentials
    );
  }

  fetchVa(query: string): Observable<Array<VirtualOperator>> {
    if (query.length < 2) {
      return of([]);
    }

    return this.http.post<Array<VirtualOperator>>(
      `${environment.serverUrl}/advisors`,
      { keyword: query }
    );

    const make = (name, surname) => {
      return {
        code: "2724U",
        name,
        surname,
      };
    };

    const op2724U: VirtualOperator = {
      code: "2724U",
      name: "Robert",
      surname: "Sumala Duwala Pnu",
    };

    return of([
      make("i", "'m the"),
      make("d", "operator"),
      make("w", "my pocket"),
      make("c", "alculator"),
      make("B", "EEP BOOP"),
      op2724U,
    ]).pipe(delay(380));
  }

  activationMock(
    token: string
  ): Observable<{ success: boolean; error: string | null }> {
    return of(token).pipe(
      mapTo({ success: false, error: "You are too dumb to activate!" }),
      delay(180)
    );
  }

  activate(token: String): Observable<any> {
    return this.http.get(
      `${environment.serverUrl}/customers/signup/activation/${token}`
    );
  }

  decodeJWTToken(token: string) {
    return this.jwtDecoder.decodeToken(token);
  }

  decodeIdToken(token: string) {
    return this.jwtDecoder.decodeToken(token);
  }

  fetchUserData() {
    return this.http.get(`${environment.serverUrl}/customers/profile`);
  }

  // TODO GET ID AND TOKEN FIELDS
  getPageToRedirect(n: any): string {
    let targetPage;
    var isCustomer =
      n.payload.id[`${environment.claimsPrefix}/${FEATURES_FIELD}`].indexOf(
        "homeCustomer"
      ) > -1;
    var isMobile = n.payload.isMobile || n.payload.fromApp;
    if (isCustomer && !isMobile) {
      targetPage = "/customer";
      // this.router.navigateByUrl('/customer');
    } else if (isCustomer && isMobile) {
      // targetPage = "/customer/menu";
      targetPage = "/customer";
      // this.router.navigateByUrl('/customer');
    } else {
      targetPage = "/financial-advisor";
      // this.router.navigateByUrl('/financial-advisor');
    }

    // //console.log("features",n.payload.id[`${environment.claimsPrefix}/${FEATURES_FIELD}`]);
    // //console.log("GET PAGE:", targetPage);
    return targetPage;
  }
}
