import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

import * as zxcvbn from 'zxcvbn';
import { ZXCVBNResult } from 'zxcvbn';

@Directive({
  selector: 'input[appPasswordMeter]'
})
export class PasswordMeterDirective {

  @Output()
  strengthChange: EventEmitter<number> = new EventEmitter();

  @HostListener('keyup', ['$event.target.value'])
  onkeyup(password) {
    const result: ZXCVBNResult = zxcvbn(password);
    this.strengthChange.emit( result.score );
  }

  constructor() { }

}
