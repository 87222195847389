import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./containers/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PasswordSecurityComponent } from "./components/password-security/password-security.component";
import { PasswordMeterDirective } from "./directives/password-meter.directive";
import { FocusStepDirective } from "./directives/focus-step.directive";
import { FocusWalkerDirective } from "./directives/focus-walker.directive";
import { SlideshowComponent } from "./components/slideshow/slideshow.component";
import { HeaderComponent } from "./components/header/header.component";
import { RecoveryTypeSelectorComponent } from "./components/recovery-type-selector/recovery-type-selector.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PasswordGaugeComponent } from "./components/password-gauge/password-gauge.component";
import { OptionListComponent } from "./components/option-list/option-list.component";
import { OptionDateComponent } from "./components/option-date/option-date.component";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { ProfilePopupComponent } from "./components/profile-popup/profile-popup.component";
import { RouterModule } from "@angular/router";
import { OptionInputComponent } from "./components/option-input/option-input.component";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { FilterTagsComponent } from "./components/table/filter-tags/filter-tags.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { TablePagingComponent } from "./components/table/table-paging/table-paging.component";
import { HoverTooltipDirective } from "./directives/hover-tooltip.directive";
import { RegistrationModalComponent } from "./components/registration-modal/registration-modal.component";
import { UppercaseDirective } from "./directives/uppercase.directive";
import { FocusPlaceholderDirective } from "./directives/focus-placeholder.directive";
import { NgxMaskModule } from "ngx-mask";
import { HighlightPipe } from "./pipes/highlight";
import { LoginErrorModalComponent } from "./components/login-error-modal/login-error-modal.component";
import { RegistrationErrorModalComponent } from "./components/registration-error-modal/registration-error-modal.component";
import { RegistrationNotAllowedModalComponent } from "./components/registration-not-allowed-modal/registration-not-allowed-modal.component";
import { LoginInactiveModalComponent } from "./components/login-inactive-modal/login-inactive-modal.component";
import { NoPasteDirective } from "./directives/no-paste.directive";
import { VirtualAdvisorPickerComponent } from "./components/virtual-advisor-picker/virtual-advisor-picker.component";
import { MultiCustomerPickerComponent } from "./components/multi-customer-picker/multi-customer-picker.component";
import { OptionDateMonitoringComponent } from "./components/option-date-monitoring/option-date-monitoring.component";
import { AdvisorPickerMonitoringComponent } from "./components/advisor-picker-monitoring/advisor-picker-monitoring.component";
import { HeaderMobileComponent } from "./components/header-mobile/header-mobile.component";
import { HeaderImageComponent } from "@app/shared/components/header/header-image/header-image.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { CustomHeader } from "./components/custom-header/custom-header";
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
} from "@angular/material";
import { SingleDateComponent } from "./components/single-date/single-date.component";

const DECLARATIONS = [
  // COMPONENTS
  NavbarComponent,
  FooterComponent,
  HeaderComponent,
  SlideshowComponent,
  PasswordGaugeComponent,
  ProfilePopupComponent,
  TablePagingComponent,
  RegistrationModalComponent,
  LoginErrorModalComponent,
  RegistrationErrorModalComponent,
  RegistrationNotAllowedModalComponent,
  LoginInactiveModalComponent,
  SingleDateComponent,

  FilterTagsComponent,

  BreadcrumbsComponent,
  HeaderMobileComponent,
  // DIRECTIVES
  PasswordMeterDirective,
  FocusStepDirective,
  FocusWalkerDirective,
  HoverTooltipDirective,
  UppercaseDirective,

  FocusPlaceholderDirective,
  NoPasteDirective,

  HighlightPipe,
];

const ENTRY_COMPONENTS = [
  // Password Security Overlay ( Login and Registration )
  PasswordSecurityComponent,
  // Recovery Overlay ( Login )
  RecoveryTypeSelectorComponent,
  RegistrationModalComponent,
  LoginErrorModalComponent,
  RegistrationErrorModalComponent,
  RegistrationNotAllowedModalComponent,
  LoginInactiveModalComponent,
  // Options Components ( TABLE > FILTERS component mostly)
  OptionListComponent,
  OptionDateComponent,
  OptionInputComponent,
  OptionDateMonitoringComponent,
  CustomHeader,
  // Table Tooltip ( Hover "Etichette" )
  TooltipComponent,
  VirtualAdvisorPickerComponent,
  MultiCustomerPickerComponent,
  AdvisorPickerMonitoringComponent,
];

@NgModule({
  declarations: [...DECLARATIONS, ...ENTRY_COMPONENTS, HeaderImageComponent],
  exports: [...DECLARATIONS, HeaderImageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  entryComponents: ENTRY_COMPONENTS,
})
export class SharedModule {}
