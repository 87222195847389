import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { DateAdapter, MatDatepicker } from "@angular/material";
import { isNullOrUndefined } from "util";
import { CustomHeader } from "../custom-header/custom-header";

@Component({
  selector: "app-option-date",
  templateUrl: "./option-date.component.html",
  styleUrls: ["./option-date.component.scss"],
})
export class OptionDateComponent implements OnInit {
  @ViewChild(MatDatepicker) datePicker: MatDatepicker<Date>;
  customHeader = CustomHeader;

  select: EventEmitter<string> = new EventEmitter();

  value: any;

  fromDate: Date = null;
  toDate: Date = null;
  singleDate: Date = null;
  multiYear: any;
  year: any;

  private selfClose: () => void;

  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnInit() {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  onOpen(datePicker) {
    if (isNullOrUndefined(this.selfClose)) {
      this.selfClose = datePicker.close;
    }
    var windowClickEvent = (e) => {
      if (
        !e.target.className.includes("input") &&
        !document.getElementById(datePicker.id).contains(e.target) &&
        !(this.multiYear && this.multiYear.contains(e.target)) &&
        !(this.year && this.year.contains(e.target))
      ) {
        onClose();
      }

      this.multiYear = document.querySelector("mat-multi-year-view");
      this.year = document.querySelector("mat-year-view");
    };
    window.addEventListener("click", windowClickEvent);

    datePicker.close = () => {};
    var onClose = () => {
      window.removeEventListener("click", windowClickEvent);
      datePicker.close = this.selfClose;
      this.selfClose = undefined;
      datePicker.close();
    };
    setTimeout(() => {
      var matCalendar = document.getElementById(datePicker.id);
      matCalendar.style.height = "auto";
      var annullaButton = document.createElement("button");
      var annullaText = document.createTextNode("Annulla");
      annullaButton.classList.add("btn", "btn-reset");
      annullaButton.appendChild(annullaText);
      annullaButton.addEventListener("click", () => {
        datePicker._datepickerInput.value = "";
        onClose();
      });
      var confermaButton = document.createElement("button");
      var confermaText = document.createTextNode("Conferma");
      confermaButton.classList.add("btn", "btn-confirm");
      confermaButton.appendChild(confermaText);
      confermaButton.addEventListener("click", () => {
        onClose();
      });
      var calendarFooter = document.createElement("div");
      calendarFooter.classList.add("calendar-footer");

      calendarFooter.appendChild(annullaButton);
      calendarFooter.appendChild(confermaButton);
      matCalendar.appendChild(calendarFooter);
    }, 0);
  }

  selectDate(date) {
    this.value = date;
  }

  selectRange(from, to) {
    this.value = { from, to };
  }

  selectPreset(type) {
    this.value = type;
    this.singleDate = null;
  }

  setFromTo() {
    this.singleDate = null;
    this.value = null;
  }

  setSingleDate(date) {
    this.value = "";
    this.fromDate = null;
    this.toDate = null;
  }

  apply() {
    if (this.singleDate) {
      this.select.emit(this.prettyDate(this.singleDate));
      this.select.complete();
      return;
    }

    if (this.value) {
      this.select.emit(this.value);
      this.select.complete();
      return;
    }

    if (this.fromDate && this.toDate) {
      this.select.emit(
        this.prettyDate(this.fromDate) + " - " + this.prettyDate(this.toDate)
      );
      this.select.complete();
    }

    if (this.value) {
      this.select.emit(this.value);
      this.select.complete();
      return;
    }
  }

  isSingleDate() {
    return (
      this.singleDate !== null && this.fromDate === null && this.toDate === null
    );
  }

  prettyDate(date: Date) {
    let y: number | string = date.getFullYear();
    let m: number | string = date.getMonth() + 1;
    let d: number | string = date.getDate();

    if (d < 10) {
      d = "0" + d;
    }

    if (m < 10) {
      m = "0" + m;
    }

    return `${d}/${m}/${y}`;
  }

  get singleDatePretty() {
    if (this.singleDate) {
      return this.prettyDate(this.singleDate);
    }
    return null;
  }
}
