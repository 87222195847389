import { EventEmitter, Output, Component } from '@angular/core';

@Component({
    template: `
        <div>
            <i (click)="close.emit()" class="icon-close close"></i>
            <div class="modal-view">
                <i class="icon-exclamation-triangle-o"></i>
                <h2>Attenzione</h2>
                <p>Alcuni campi nel form non sono stati inseriti correttamente.</p>
            </div>
        </div>
    `,
    styleUrls: ['./style.scss']
})
export class RegistrationErrorModalComponent {

    @Output()
    close: EventEmitter<void> = new EventEmitter();

}